import React from 'react';

import Capability from './Capability';

const CapabilityList = () => {
  const CAPABILITIES = [
    {
      id: 0,
      description: 'Eficientizar los procesos de generación de receta cumpliendo todas las normativas y regulaciones medicas dominicanas.',
      iconClass: 'fa-cog',
    },
    {
      id: 1,
      description: 'Claridad en el proceso de la emisión de una receta médica.',
      iconClass: 'far fa-id-card',
    },
    {
      id: 2,
      description: 'Fomentar la ética de nuestros médicos.',
      iconClass: 'fas fa-user-md',
    },
    {
      id: 3,
      description: 'Establecer el compromiso para dar un servicio de calidad.',
      iconClass: 'fa-handshake-o',
    },
    {
      id: 4,
      description: 'Transparencia.',
      iconClass: 'fas fa-search',
    }
  ]

  const [capabilities] = React.useState(CAPABILITIES);

  return (
    <>
      {capabilities.map((capability) => <Capability key={capability.id} {...capability} />)}
    </>
  )
}

export default CapabilityList;
