import React from "react";

import pic1 from "../../images/contact-0.jpg";
import pic2 from "../../images/contact-1.jpg";

import Feature from "./Feature";

const Features = () => {
  const FEATURES = [
    {
      href: "/#",
      image: pic1,
      heading: "Dr. Alfredo Yeger Vallejo",
      description:
        "Doctor Alfredo Yeger Vallejo, profesional de la medicina y especializado en medicina interna y cardiología. Egresado de la Universidad Autónoma de Santo Domingo, en la cual curso la carrera de medicina en el año 1979 hasta el año 1987, obteniendo el título de Doctor en Medicina. En 1993 marcho a Venezuela a cursar la especialidad de medicina interna en el Hospital Militar de la ciudad de caracas, Venezuela., Finalizando la especialidad en 1996 y ejerciendo alrededor de dos años, Luego ingresa al instituto de cardiología para realizar la especialidad del mismo en año 1998 hasta el 2001, finalizada esa etapa ingresa a Corazones Unidos donde actualmente ejerce como internista y cardiólogo.",
      to: {
        href: "/generic",
        label: "Learn more",
      },
    },
    {
      href: "/#",
      image: pic2,
      heading: "Lic. Alfredo Yeger Toribio",
      description:
        "Joven profesional del derecho, Fundador de Yeger Toribio Abogados. Inicio sus estudios de Derecho en el año 2010 matriculándose en la Pontificia Universidad Católica Madre y Maestra PUCMM, obteniendo el título de Licenciado en Derecho en el año 2014, ese mismo año parte a España a la Universidad de Castilla La Mancha donde para cursar una Maestría en Justicia Constitucional y Derechos Fundamentales – Maestría de doble titulación por la PUCMM y la Universidad de Castilla La Mancha. En 2016 se dirige a Argentina a la Universidad Austral de Buenos Aires a realizar un máster en Derecho Administrativo y Procedimiento Administrativo. Dentro de su trayectoria profesional es socio de la firma Fernandez Yeher y Mercedes Abogados, es asesor legal del Ministerio de Cultura con la parte de compras y contrataciones públicas. También participo en la defensa del recurso interpuesto a la empresa Lácteos Dominicanos LADOM, representando los intereses de Helados Bon (año 2018) y dirigió el equipo de conformación de una lotería electrónica en cuanto a la obtención de la licencia y constitución de la compañía para que esta funcione como lotería electrónica.",
      to: {
        href: "/generic",
        label: "Learn more",
      },
    },
  ];

  const [features] = React.useState(FEATURES);

  return (
    <section id="one" className="wrapper style2 spotlights">
      {features.map((feature) => (
        <Feature key={feature.heading} {...feature} />
      ))}
    </section>
  );
};

export default Features;
