import React, { useState } from 'react';


function getPreview (isFullPreview, description) {
  return isFullPreview ? description : description.slice(0, description.indexOf('.')+1);
}

function Feature ({href, image, heading, description, to}) {
  const [isFullPreview, setPreview] = useState(false);

  return (
    <section>
      <a href={href} className="image" alt="image">
        <img src={image} alt="" data-position="center center" className="img-contained"/>
      </a>
      <div className="content list-color">
        <div className="inner">
          <h2>{heading}</h2>
          <p >{getPreview(isFullPreview, description)}</p>
          <ul className="actions">
            <li>
              <button className="button" onClick={() => {
                setPreview(!isFullPreview);
                getPreview(isFullPreview, description);
              }}>
                { isFullPreview ? 'Ver Menos' : 'Ver Más'}
              </button>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
}

export default Feature;
