import React from 'react';

import ContactForm from './ContactForm';
import ContactInformation from './ContactInformation';

const Contact = () =>
  <section id="three" className="wrapper style-contact fade-up">
    <div className="inner">
      <h2>Contacto</h2>
      <p>
        Déjenos un mensaje, estaremos encantados de ofrecer la información que necesite.
      </p>
      <div className="split style1">
        <ContactForm />
        <ContactInformation />
      </div>
    </div>
  </section>

export default Contact;
