import React from "react";
import { Link } from "gatsby";

import CapabilityList from "./CapabilityList";

const Capabilities = () => (
  <section id="two" className="wrapper style3 fade-up">
    <div className="inner">
      <h2>¡Que Hacemos!?</h2>
      <p>
        Con este sistema se podría fiscalizar de una manera más adecuada el uso
        de los medicamentos no solo de la dosis que se le prescriba sino también
        una adecuada racionalización de manejos de medicamentos al paciente,
        dándole al profesional en farmacia y al profesional médico una
        responsabilidad mayor. Podrán ser usados. Dicha receta viene a llenar
        vacio en lo relacionado al control del expendio de los expendios de
        medicamentos así como el uso indiscriminado que se lleva a cabo uso
        indiscriminado que actualmente se lleva a cabo con la falsificación de
        los productos y dar más confianza a las compañías aseguradoras medicas y
        al mismo profesional médico.
      </p>
      <p>
        Tenemos como misión dar el mejor servicio en el proceso de la
        prescripción y adquisición de los tratamientos, además de facilitar la
        orientación al paciente de la ubicación de los centros privados,
        asistenciales y de las farmacias.
      </p>
      <div className="features">
        <CapabilityList />
      </div>
    </div>
  </section>
);

export default Capabilities;
