import React from "react";

const ContactInformation = () => (
  <section>
    <ul className="contact">
      <li>
        <h3>Dirección</h3>
        <span>
          Calle Madame Curie #3
          <br />
          La Esperilla.
          <br />
          Santo Domingo, Rep. Dom.
        </span>
      </li>
      <li>
        <h3>Email</h3>
        <a href="mailto:info@recdigmedica.com">info@recdigmedica.com</a>
      </li>
      <li>
        <h3>Teléfono</h3>
        <span>(809) 916-2448</span>
      </li>
      <li>
        <h3>Social</h3>
        <ul className="icons">
          <li>
            <a
              href="https://twitter.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="fa-twitter"
            >
              <span className="label">Twitter</span>
            </a>
          </li>
          <li>
            <a
              href="https://www.linkedin.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="fa-linkedin"
            >
              <span className="label">LinkedIn</span>
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </section>
);

export default ContactInformation;
