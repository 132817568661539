import React from "react";
import Scroll from "../common/Scroll";

const Introduction = () => (
  <section id="intro" className="wrapper style-blank fullscreen fade-up">
    <div className="inner">
      <ul className="actions">
        <li>
          <Scroll type="id" element="one">
            <a href="#one" className="">
              v
            </a>
          </Scroll>
        </li>
      </ul>
    </div>
  </section>
);

export default Introduction;
